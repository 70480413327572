/** Suggested size for banner images: 500×453 (for best experience)  */

import abrahamLincoln from './abraham-lincoln.png'
import albertEinstein from './albert-einstein.png'
import babeRuth from './babe-ruth.png'
import beethoven from './beethoven.png'
import benitoJuarez from './benito-juarez.png'
import benjaminFranklin from './benjamin-franklin.png'
import brighamYoung from './brigham-young.png'
import brittanyJepsen from './brittany-jepsen.png'
import charlesDickens from './charles-dickens.png'
import elvisPresley from './elvis-presley.png'
import emilyDickinson from './emily-dickinson.png'
import franklinDRoosevelt from './franklin-d-roosevelt.png'
import fridaKahlo from './frida-kahlo.png'
import gabrielGarciaMarquez from './gabriel-garcia-marquez.png'
import georgeVI from './george-vi.png'
import georgeWashington from './george-washington.png'
import helenKeller from './helen-keller.png'
import janeAusten from './jane-austen.png'
import jimmerFredette from './jimmer-fredette.png'
import johnWayne from './john-wayne.png'
import josephSmith from './joseph-smith.png'
import juanDeGaray from './juan-de-garay.png'
import kirbyHeyborne from './kirby-heyborne.png'
import lucilleBall from './lucille-ball.png'
import margaretThatcher from './margaret-thatcher.png'
import marieAntoinette from './marie-antoinette.png'
import marilynMonroe from './marilyn-monroe.png'
import matthewModine from './matthew-modine.png'
import muhammadAli from './muhammad-ali.png'
import nelsonMandela from './nelson-mandela.png'
import pele from './pele.png'
import pocahontas from './pocahontas.png'
import princessDiana from './princess-diana.png'
import queenElizabethII from './queen-elizabeth-ii.png'
import queenVictoria from './queen-victoria.png'
import robertoClemente from './roberto-clemente.png'
import rosaParks from './rosa-parks.png'
import seanAstin from './sean-astin.png'
import shirleyTemple from './shirley-temple.png'
import simonBolivar from './simon-bolivar.png'
import steveRockwood from './steve-rockwood.png'
import susanBAnthony from './susan-b-anthony.png'
import thaisPacholek from './thais-pacholek.png'
import tiradentes from './tiradentes.png'
import vicenteFernandez from './vicente-fernandez.png'
import trumanAngell from './truman-angell.png'
import waltDisney from './walt-disney.png'
import wilburWright from './wilbur-wright.png'
import williamShakespeare from './william-shakespeare.png'
import winstonChurchill from './winston-churchill.png'

const portraits = {
  abrahamLincoln,
  albertEinstein,
  babeRuth,
  beethoven,
  benitoJuarez,
  benjaminFranklin,
  brighamYoung,
  brittanyJepsen,
  charlesDickens,
  elvisPresley,
  emilyDickinson,
  franklinDRoosevelt,
  fridaKahlo,
  gabrielGarciaMarquez,
  georgeVI,
  georgeWashington,
  helenKeller,
  janeAusten,
  jimmerFredette,
  johnWayne,
  josephSmith,
  juanDeGaray,
  kirbyHeyborne,
  lucilleBall,
  margaretThatcher,
  marieAntoinette,
  marilynMonroe,
  matthewModine,
  muhammadAli,
  nelsonMandela,
  pele,
  pocahontas,
  princessDiana,
  queenElizabethII,
  queenVictoria,
  robertoClemente,
  rosaParks,
  seanAstin,
  shirleyTemple,
  simonBolivar,
  steveRockwood,
  susanBAnthony,
  thaisPacholek,
  tiradentes,
  vicenteFernandez,
  trumanAngell,
  waltDisney,
  wilburWright,
  williamShakespeare,
  winstonChurchill,
}

export default portraits
