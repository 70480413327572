// eslint-disable-next-line import/prefer-default-export
export const ZION_CMS_SITES = [
  'canada',
  'cabo-verde',
  'danmark',
  'deutschland',
  'espana',
  'europe',
  'italia',
  'norge',
  'portugal',
  'stories',
  'suomi',
  'sverige',
  'united-kingdom',
  'united-states',
  'united-states-canada',
]
