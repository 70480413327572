/** Suggested size for banner images: 500×453 (for best experience)  */

import albertEinstein from './albert-einstein.jpg'
import babeRuth from './babe-ruth.jpg'
import benitoJuarez from './benito-juarez.jpg'
import charlesDickens from './charles-dickens.jpg'
import elvisPresley from './elvis.jpg'
import georgeVI from './george-vi.jpg'
import georgeWashington from './george-washington.jpg'
import janeAusten from './jane-austen.jpg'
import juanDeGaray from './juan-de-garay.jpg'
import matthewModine from './matthew-modine.jpg'
import queenElizabethII from './queen-elizabeth-ii.jpg'
import queenVictoria from './queen-victoria.jpg'
import robertoClemente from './roberto-clemente.jpg'
import simonBolivar from './simon-bolivar.jpg'
import steveRockwood from './steve-rockwood.jpg'
import thaisPacholek from './thais-pacholek.jpg'
import tiradentes from './tiradentes.jpg'
import waltDisney from './walt-disney.jpg'
import williamShakespeare from './william-shakespeare.jpg'

const portraits = {
  albertEinstein,
  babeRuth,
  benitoJuarez,
  charlesDickens,
  elvisPresley,
  georgeVI,
  georgeWashington,
  janeAusten,
  juanDeGaray,
  matthewModine,
  queenElizabethII,
  queenVictoria,
  robertoClemente,
  simonBolivar,
  steveRockwood,
  thaisPacholek,
  tiradentes,
  waltDisney,
  williamShakespeare,
}

export default portraits
