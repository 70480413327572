import abrahamLincoln from './abraham-lincoln.jpg'
import albertEinstein from './albert-einstein.jpg'
import babeRuth from './babe-ruth.jpg'
import beethoven from './beethoven.jpg'
import benitoJuarez from './benito-juarez.jpg'
import benjaminFranklin from './benjamin-franklin.jpg'
import brighamYoung from './brigham-young.jpg'
import brittanyJepsen from './brittany-jepsen.jpg'
import charlesDickens from './charles-dickens.jpg'
import elvisPresley from './elvis-presley.jpg'
import emilyDickinson from './emily-dickinson.jpg'
import franklinDRoosevelt from './franklin-d-roosevelt.jpg'
import fridaKahlo from './frida-kahlo.jpg'
import gabrielGarciaMarquez from './gabriel-garcia-marquez.jpg'
import georgeVI from './george-vi.jpg'
import georgeWashington from './george-washington.jpg'
import helenKeller from './helen-keller.jpg'
import janeAusten from './jane-austen.jpg'
import jimmerFredette from './jimmer-fredette.jpg'
import johnWayne from './john-wayne.jpg'
import josephSmith from './joseph-smith.jpg'
import juanDeGaray from './juan-de-garay.jpg'
import kirbyHeyborne from './kirby-heyborne.jpg'
import lucilleBall from './lucille-ball.jpg'
import margaretThatcher from './margaret-thatcher.jpg'
import marieAntoinette from './marie-antoinette.jpg'
import marilynMonroe from './marilyn-monroe.jpg'
import matthewModine from './matthew-modine.jpg'
import muhammadAli from './muhammad-ali.jpg'
import nelsonMandela from './nelson-mandela.jpg'
import pele from './pele.jpg'
import pocahontas from './pocahontas.jpg'
import princessDiana from './princess-diana.jpg'
import queenElizabethII from './queen-elizabeth-ii.jpg'
import queenVictoria from './queen-victoria.jpg'
import robertoClemente from './roberto-clemente.jpg'
import rosaParks from './rosa-parks.jpg'
import seanAstin from './sean-astin.jpg'
import shirleyTemple from './shirley-temple.jpg'
import simonBolivar from './simon-bolivar.jpg'
import steveRockwood from './steve-rockwood.jpg'
import susanBAnthony from './susan-b-anthony.jpg'
import thaisPacholek from './thais-pacholek.jpg'
import tiradentes from './tiradentes.jpg'
import vicenteFernandez from './vicente-fernandez.jpg'
import trumanAngell from './truman-angell.jpg'
import waltDisney from './walt-disney.jpg'
import wilburWright from './wilbur-wright.jpg'
import williamShakespeare from './william-shakespeare.jpg'
import winstonChurchill from './winston-churchill.jpg'

const portraits = {
  abrahamLincoln,
  albertEinstein,
  babeRuth,
  beethoven,
  benitoJuarez,
  benjaminFranklin,
  brighamYoung,
  brittanyJepsen,
  charlesDickens,
  elvisPresley,
  emilyDickinson,
  franklinDRoosevelt,
  fridaKahlo,
  gabrielGarciaMarquez,
  georgeVI,
  georgeWashington,
  helenKeller,
  janeAusten,
  jimmerFredette,
  johnWayne,
  josephSmith,
  juanDeGaray,
  kirbyHeyborne,
  lucilleBall,
  margaretThatcher,
  marieAntoinette,
  marilynMonroe,
  matthewModine,
  muhammadAli,
  nelsonMandela,
  pele,
  pocahontas,
  princessDiana,
  queenElizabethII,
  queenVictoria,
  robertoClemente,
  rosaParks,
  seanAstin,
  shirleyTemple,
  simonBolivar,
  steveRockwood,
  susanBAnthony,
  thaisPacholek,
  tiradentes,
  vicenteFernandez,
  trumanAngell,
  waltDisney,
  wilburWright,
  williamShakespeare,
  winstonChurchill,
}

export default portraits
