import { baseUrl } from '@fs/zion-config'
import portraits from '../images/portraits'
import cardPortraits from '../images/card-portraits'
import backupShareImages from '../images/share-backup'

export const famousPeople = {
  ABRAHAM_LINCOLN: 'abraham-lincoln',
  ALBERT_EINSTEIN: 'albert-einstein',
  BABE_RUTH: 'babe-ruth',
  BEETHOVEN: 'beethoven',
  BENITO_JUAREZ: 'benito-juarez',
  BENJAMIN_FRANKLIN: 'benjamin-franklin',
  BRIGHAM_YOUNG: 'brigham-young',
  BRITTANY_JEPSEN: 'brittany-jepsen',
  CHARLES_DICKENS: 'charles-dickens',
  ELVIS_PRESLEY: 'elvis-presley',
  EMILY_DICKINSON: 'emily-dickinson',
  FRANKLIN_D_ROOSEVELT: 'franklin-d-roosevelt',
  FRIDA_KAHLO: 'frida-kahlo',
  GABRIEL_GARCIA_MARQUEZ: 'gabriel-garcia-marquez',
  GEORGE_VI: 'george-vi',
  GEORGE_WASHINGTON: 'george-washington',
  HELEN_KELLER: 'helen-keller',
  JANE_AUSTEN: 'jane-austen',
  JIMMER_FREDETTE: 'jimmer-fredette',
  JOHN_WAYNE: 'john-wayne',
  JOSEPH_SMITH: 'joseph-smith',
  JUAN_DE_GARAY: 'juan-de-garay',
  KIRBY_HEYBORNE: 'kirby-heyborne',
  LUCILLE_BALL: 'lucille-ball',
  MARGARET_THATCHER: 'margaret-thatcher',
  MARIE_ANTOINETTE: 'marie-antoinette',
  MATTHEW_MODINE: 'matthew-modine',
  MUHAMMAD_ALI: 'muhammad-ali',
  NELSON_MANDELA: 'nelson-mandela',
  POCAHONTAS: 'pocahontas',
  PRINCESS_DIANA: 'princess-diana',
  QUEEN_ELIZABETH_II: 'queen-elizabeth-ii',
  QUEEN_VICTORIA: 'queen-victoria',
  ROBERTO_CLEMENTE: 'roberto-clemente',
  ROSA_PARKS: 'rosa-parks',
  SEAN_ASTIN: 'sean-astin',
  SHIRLEY_TEMPLE: 'shirley-temple',
  SIMON_BOLIVAR: 'simon-bolivar',
  STEVE_ROCKWOOD: 'steve-rockwood',
  SUSAN_B_ANTHONY: 'susan-b-anthony',
  THAIS_PACHOLEK: 'thais-pacholek',
  TIRADENTES: 'tiradentes',
  TRUMAN_ANGELL: 'truman-angell',
  WALT_DISNEY: 'walt-disney',
  WILBUR_WRIGHT: 'wilbur-wright',
  WILLIAM_SHAKESPEARE: 'william-shakespeare',
  WINSTON_CHURCHILL: 'winston-churchill',
}

export const famousPeoplePendingApproval = {
  MARILYN_MONROE: 'marilyn-monroe',
  PELE: 'pele',
  VICENTE_FERNANDEZ: 'vicente-fernandez',
}

export const famousPeopleRoutes = Object.values(famousPeople)
export const famousPeoplePendingApprovalRoutes = Object.values(famousPeoplePendingApproval)

const famousPeopleDetails = {
  [famousPeople.ABRAHAM_LINCOLN]: {
    personId: 'LZJW-C31',
    color: '#f68d2e',
    givenName: 'Abraham',
    surname: 'Lincoln',
    fullName: 'Abraham Lincoln',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.abraham-lincoln',
      '16th US president, in office during the US Civil War; issued the Emancipation Proclamation.',
    ],
    cardPortrait: cardPortraits.abrahamLincoln,
    portrait: portraits.abrahamLincoln,
    backupShareImage: backupShareImages.abrahamLincoln,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.ALBERT_EINSTEIN]: {
    personId: 'G3Q7-Z6T',
    color: '#f68d2e',
    givenName: 'Albert',
    surname: 'Einstein',
    fullName: 'Albert Einstein',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.albert-einstein',
      'German-born theoretical physicist best known for developing the theory of relativity.',
    ],
    cardPortrait: cardPortraits.albertEinstein,
    portrait: portraits.albertEinstein,
    backupShareImage: backupShareImages.albertEinstein,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.BABE_RUTH]: {
    personId: 'LRBP-BB8',
    color: '#f68d2e',
    givenName: 'Babe',
    surname: 'Ruth',
    fullName: 'Babe Ruth',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.babe-ruth',
      'Widely considered one of the greatest American baseball players of all time. Excelled at pitching and batting.',
    ],
    cardPortrait: cardPortraits.babeRuth,
    portrait: portraits.babeRuth,
    backupShareImage: backupShareImages.babeRuth,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.BEETHOVEN]: {
    personId: 'LCYS-KPG',
    color: '#f68d2e',
    givenName: 'Ludwig',
    surname: 'Beethoven',
    fullName: 'Ludwig van Beethoven',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.beethoven',
      'German composer and pianist. One of the most performed composers of classical music today.',
    ],
    cardPortrait: cardPortraits.beethoven,
    portrait: portraits.beethoven,
    backupShareImage: backupShareImages.beethoven,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.BENITO_JUAREZ]: {
    personId: 'MP52-7DH',
    color: '#f68d2e',
    givenName: 'Benito',
    surname: 'Juárez',
    fullName: 'Benito Juárez',
    gender: 'MALE',
    isHispanic: true,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.benito-juarez',
      'First Mexican president of indigenous origin. Served from 1858 until his death in 1872.',
    ],
    cardPortrait: cardPortraits.benitoJuarez,
    portrait: portraits.benitoJuarez,
    backupShareImage: backupShareImages.benitoJuarez,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.BENJAMIN_FRANKLIN]: {
    personId: 'LJLQ-WRC',
    color: '#f68d2e',
    givenName: 'Benjamin',
    surname: 'Franklin',
    fullName: 'Benjamin Franklin',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.benjamin-franklin',
      'American Founding Father, inventor, scientist, diplomat, political philosopher, and more.',
    ],
    cardPortrait: cardPortraits.benjaminFranklin,
    portrait: portraits.benjaminFranklin,
    backupShareImage: backupShareImages.benjaminFranklin,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.BRIGHAM_YOUNG]: {
    personId: 'KWJH-9QN',
    color: '#f68d2e',
    givenName: 'Brigham',
    surname: 'Young',
    fullName: 'Brigham Young',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.brigham-young',
      'Second leader of The Church of Jesus Christ of Latter-day Saints and first governor of the Utah Territory.',
    ],
    cardPortrait: cardPortraits.brighamYoung,
    portrait: portraits.brighamYoung,
    backupShareImage: backupShareImages.brighamYoung,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.BRITTANY_JEPSEN]: {
    cisId: 'cis.user.MMMM-S975',
    color: '#f68d2e',
    givenName: 'Brittany',
    surname: 'Jepsen',
    fullName: 'Brittany Jepsen',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: true,
    description: [
      'famous-person-page.ayr.description.brittany-jepsen',
      'Award-winning designer and blogger dedicated to the pursuit of artful and colorful living. Founder of The House That Lars Built. She and her team believe that making things with your hands connects you to your soul.',
    ],
    cardPortrait: cardPortraits.brittanyJepsen,
    portrait: portraits.brittanyJepsen,
    backupShareImage: backupShareImages.brittanyJepsen,
    eventId: getRootstech2022EventId(),
    languageSpecific: ['en'],
    // As of 30 March 2023, marketing said Brittany’s page can stay up indefinitely
    pageIsExpired: false,
  },
  [famousPeople.CHARLES_DICKENS]: {
    personId: 'LCY4-4RK',
    color: '#f68d2e',
    givenName: 'Charles',
    surname: 'Dickens',
    fullName: 'Charles Dickens',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.charles-dickens',
      'English writer and social critic, regarded by many as the greatest novelist of the Victorian era.',
    ],
    cardPortrait: cardPortraits.charlesDickens,
    portrait: portraits.charlesDickens,
    backupShareImage: backupShareImages.charlesDickens,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.ELVIS_PRESLEY]: {
    personId: 'L64M-357',
    color: '#f68d2e',
    givenName: 'Elvis',
    surname: 'Presley',
    fullName: 'Elvis Presley',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.elvis-presley',
      'This American singer and actor became a worldwide sensation known as the “King of Rock and Roll.”',
    ],
    cardPortrait: cardPortraits.elvisPresley,
    portrait: portraits.elvisPresley,
    backupShareImage: backupShareImages.elvisPresley,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.EMILY_DICKINSON]: {
    personId: 'LTJ5-QPL',
    color: '#f68d2e',
    givenName: 'Emily',
    surname: 'Dickinson',
    fullName: 'Emily Dickinson',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.emily-dickinson',
      'Early American poet from Massachusets and an important figure in American poetry.',
    ],
    cardPortrait: cardPortraits.emilyDickinson,
    portrait: portraits.emilyDickinson,
    backupShareImage: backupShareImages.emilyDickinson,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.FRANKLIN_D_ROOSEVELT]: {
    personId: 'LH1D-F9S',
    color: '#f68d2e',
    givenName: 'Franklin D.',
    surname: 'Roosevelt',
    fullName: 'Franklin D. Roosevelt',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.franklin-d-roosevelt',
      '32nd President of the United States, in office during the Great Depression and World War II.',
    ],
    cardPortrait: cardPortraits.franklinDRoosevelt,
    portrait: portraits.franklinDRoosevelt,
    backupShareImage: backupShareImages.franklinDRoosevelt,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.FRIDA_KAHLO]: {
    personId: 'L4Q5-1R1',
    color: '#f68d2e',
    givenName: 'Frida',
    surname: 'Kahlo',
    fullName: 'Frida Kahlo',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.frida-kahlo',
      'Mexican painter known for her portraits of others and herself, as well as Mexican inspired folk art.',
    ],
    cardPortrait: cardPortraits.fridaKahlo,
    portrait: portraits.fridaKahlo,
    backupShareImage: backupShareImages.fridaKahlo,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.GABRIEL_GARCIA_MARQUEZ]: {
    personId: 'LX37-M25',
    color: '#f68d2e',
    givenName: 'Gabriel',
    surname: 'García Márquez',
    fullName: 'Gabriel García Márquez',
    gender: 'MALE',
    isHispanic: true,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.gabriel-garcia-marquez',
      'Colombian novelist, short-story writer, screenwriter, and journalist. Winner of the Nobel Prize in 1982.',
    ],
    cardPortrait: cardPortraits.gabrielGarciaMarquez,
    portrait: portraits.gabrielGarciaMarquez,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.GEORGE_VI]: {
    personId: 'GDCN-C66',
    color: '#f68d2e',
    givenName: 'George VI',
    surname: 'George VI',
    fullName: 'George VI',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.george-vi',
      'British monarch from 1936 until his death in 1952. Father of Queen Elizabeth II.',
    ],
    cardPortrait: cardPortraits.georgeVI,
    portrait: portraits.georgeVI,
    backupShareImage: backupShareImages.georgeVI,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.GEORGE_WASHINGTON]: {
    personId: 'KNDX-MKG',
    color: '#f68d2e',
    givenName: 'George',
    surname: 'Washington',
    fullName: 'George Washington',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.george-washington',
      'American soldier and Founding Father who served as the first president of the United States from 1789 to 1797.',
    ],
    cardPortrait: cardPortraits.georgeWashington,
    portrait: portraits.georgeWashington,
    backupShareImage: backupShareImages.georgeWashington,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.HELEN_KELLER]: {
    personId: 'MZ46-1SF',
    color: '#f68d2e',
    givenName: 'Helen',
    surname: 'Keller',
    fullName: 'Helen Keller',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.helen-keller',
      'Famous American author, disability rights advocate, and political activist who was born both deaf and blind.',
    ],
    cardPortrait: cardPortraits.helenKeller,
    portrait: portraits.helenKeller,
    backupShareImage: backupShareImages.helenKeller,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.JANE_AUSTEN]: {
    personId: 'LZBF-BH4',
    color: '#f68d2e',
    givenName: 'Jane',
    surname: 'Austen',
    fullName: 'Jane Austen',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.jane-austen',
      'English writer known primarily for her  popular novels that explore the lives of women in the Victorian era.',
    ],
    cardPortrait: cardPortraits.janeAusten,
    portrait: portraits.janeAusten,
    backupShareImage: backupShareImages.janeAusten,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.JIMMER_FREDETTE]: {
    cisId: 'cis.user.MMMX-PS3K',
    color: '#f68d2e',
    givenName: 'Jimmer',
    surname: 'Fredette',
    fullName: 'Jimmer Fredette',
    gender: 'MALE',
    isHispanic: false,
    isLiving: true,
    description: [
      'famous-person-page.ayr.description.jimmer-fredette',
      'American professional basketball player known for playing both in the NBA and in China with the Shanghai Sharks. He was the leading scorer in NCAA Division I during his senior year with the BYU Cougars.',
    ],
    cardPortrait: cardPortraits.jimmerFredette,
    portrait: portraits.jimmerFredette,
    backupShareImage: backupShareImages.jimmerFredette,
    eventId: getRootstech2022EventId(),
    languageSpecific: ['en'],
    // As of 30 March 2023, marketing said Jimmer’s page can stay up indefinitely
    pageIsExpired: false,
  },
  [famousPeople.JOHN_WAYNE]: {
    personId: 'LZTB-DMS',
    color: '#f68d2e',
    givenName: 'John',
    surname: 'Wayne',
    fullName: 'John Wayne',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.john-wayne',
      'American actor famous during the golden age of Hollywood; known for his role in western and war films.',
    ],
    cardPortrait: cardPortraits.johnWayne,
    portrait: portraits.johnWayne,
    backupShareImage: backupShareImages.johnWayne,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.JOSEPH_SMITH]: {
    personId: 'KWJY-BPD',
    color: '#f68d2e',
    givenName: 'Joseph',
    surname: 'Smith',
    fullName: 'Joseph Smith Jr.',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.joseph-smith',
      'American religious leader and first prophet of The Church of Jesus Christ of Latter-day Saints.',
    ],
    cardPortrait: cardPortraits.josephSmith,
    portrait: portraits.josephSmith,
    backupShareImage: backupShareImages.josephSmith,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.JUAN_DE_GARAY]: {
    personId: 'L6NB-GMZ',
    color: '#f68d2e',
    givenName: 'Juan',
    surname: 'de Garay',
    fullName: 'Juan de Garay',
    gender: 'MALE',
    isHispanic: true,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.juan-de-garay',
      'Spanish conquistador and governor of Asunción, Paraguay. Known for founding Buenos Aires in 1580.',
    ],
    videoTextOverride: [
      'famous-person-page.famous-person-video.heading.diego.see-what-happens',
      'See Diego Torres’ reaction when he discovers that Juan de Garay is his ancestor.',
    ],
    youtubeVideoId: 'QYKqYbmcbUg',
    cardPortrait: cardPortraits.juanDeGaray,
    portrait: portraits.juanDeGaray,
    backupShareImage: backupShareImages.juanDeGaray,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.KIRBY_HEYBORNE]: {
    cisId: 'cis.user.MMMM-D1C7',
    color: '#f68d2e',
    givenName: 'Kirby',
    surname: 'Heyborne',
    fullName: 'Kirby Heyborne',
    gender: 'MALE',
    isHispanic: false,
    isLiving: true,
    description: [
      'famous-person-page.ayr.description.kirby-heyborne',
      'American actor, musician, comedian, and award-winning narrator of audio books. Best known recently as the engaging host of the BYUTV show <0>Making Good</0>.',
    ],
    profession: 'ACTOR',
    cardPortrait: cardPortraits.kirbyHeyborne,
    portrait: portraits.kirbyHeyborne,
    backupShareImage: backupShareImages.kirbyHeyborne,
    eventId: getRootstech2022EventId(),
    languageSpecific: ['en'],
    // As of 30 March 2023, marketing said Kirby’s page can stay up indefinitely
    pageIsExpired: false,
  },
  [famousPeople.LUCILLE_BALL]: {
    personId: 'L71K-6KZ',
    color: '#f68d2e',
    givenName: 'Lucille',
    surname: 'Ball',
    fullName: 'Lucille Ball',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.lucille-ball',
      'American comedienne, producer, star of “I Love Lucy,” and a pioneer in the television industry.',
    ],
    cardPortrait: cardPortraits.lucilleBall,
    portrait: portraits.lucilleBall,
    backupShareImage: backupShareImages.lucilleBall,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.MARGARET_THATCHER]: {
    personId: 'L8Y7-1X7',
    color: '#f68d2e',
    givenName: 'Margaret',
    surname: 'Thatcher',
    fullName: 'Margaret Thatcher',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.margaret-thatcher',
      'British politician, baroness, and first female prime minister of the United Kingdom. Nicknamed the “Iron Lady.”',
    ],
    cardPortrait: cardPortraits.margaretThatcher,
    portrait: portraits.margaretThatcher,
    backupShareImage: backupShareImages.margaretThatcher,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.MARIE_ANTOINETTE]: {
    personId: 'LR6L-2N4',
    color: '#f68d2e',
    givenName: 'Marie',
    surname: 'Antoinette',
    fullName: 'Marie Antoinette',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.marie-antoinette',
      'Austrian archduchess who became the last queen of France before the French Revolution.',
    ],
    cardPortrait: cardPortraits.marieAntoinette,
    portrait: portraits.marieAntoinette,
    backupShareImage: backupShareImages.marieAntoinette,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.MATTHEW_MODINE]: {
    cisId: 'cis.user.MMMZ-TMJG',
    color: '#f68d2e',
    givenName: 'Matthew',
    surname: 'Modine',
    fullName: 'Matthew Modine',
    gender: 'MALE',
    isHispanic: false,
    isLiving: true,
    description: [
      'famous-person-page.ayr.description.matthew-modine',
      'American actor, activist, and filmmaker, known for roles in <0>Full Metal Jacket</0> and <0>Stranger Things</0>.',
    ],
    // We don't want to display these because of the content on his site.
    // linkUrl: 'http://www.matthewmodine.com/',
    // linkDisplay: 'www.matthewmodine.com/',
    profession: 'ACTOR',
    youtubeVideoId: 'LWfzjiCFXP8',
    cardPortrait: cardPortraits.matthewModine,
    portrait: portraits.matthewModine,
    backupShareImage: backupShareImages.matthewModine,
    eventId: getRootstech2022EventId(),
    loggedOrOptedOutCustomText: [
      'famous-person-page.custom-text-modine',
      'Stranger Things actor Matthew Modine may be your relative.',
    ],
    isRelatedCustomText: [
      'famous-person-page.custom-text-related-modine',
      'I’m related to Stranger Things actor Matthew Modine!',
    ],
    isRelatedCustomHeading: ['famous-person-page.modine.heading.custom-text', 'Stranger things have happened'],
    customSocialSubject: [
      'famous-person-page.meta.modine.custom-text-related',
      'I’m related to Stranger Things actor Matthew Modine!',
    ],
    pageIsExpired: true,
  },
  [famousPeople.MUHAMMAD_ALI]: {
    personId: 'LLWM-ST5',
    color: '#f68d2e',
    givenName: 'Muhammad',
    surname: 'Ali',
    fullName: 'Muhammad Ali',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.muhammad-ali',
      'American professional boxer, activist, and philanthropist. Widely considered the greatest boxer of all time.',
    ],
    cardPortrait: cardPortraits.muhammadAli,
    portrait: portraits.muhammadAli,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.NELSON_MANDELA]: {
    personId: 'LKB6-KHQ',
    color: '#f68d2e',
    givenName: 'Nelson',
    surname: 'Mandela',
    fullName: 'Nelson Mandela',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.nelson-mandela',
      'South African antiapartheid revolutionary, political leader, and philanthropist.',
    ],
    cardPortrait: cardPortraits.nelsonMandela,
    portrait: portraits.nelsonMandela,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.POCAHONTAS]: {
    personId: 'LKF1-HNG',
    color: '#f68d2e',
    givenName: 'Pocahontas',
    surname: 'Pocahontas',
    fullName: 'Pocahontas',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.pocahontas',
      'Native American woman of the Powhatan people, known for her association with the Jamestown colonists.',
    ],
    cardPortrait: cardPortraits.pocahontas,
    portrait: portraits.pocahontas,
    backupShareImage: backupShareImages.pocahontas,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.PRINCESS_DIANA]: {
    personId: 'L5XN-6LV',
    color: '#f68d2e',
    givenName: 'Princess Diana',
    surname: 'Princess Diana',
    fullName: 'Princess Diana',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.princess-diana',
      'Thrust into public life by marrying Prince Charles, she become widely known for her style and activism.',
    ],
    cardPortrait: cardPortraits.princessDiana,
    portrait: portraits.princessDiana,
    backupShareImage: backupShareImages.princessDiana,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.QUEEN_ELIZABETH_II]: {
    personId: 'G71M-BYY',
    color: '#f68d2e',
    givenName: 'Elizabeth',
    surname: 'Alexandra',
    fullName: 'Queen Elizabeth II',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    backupShareImage: backupShareImages.queenElizabethII,
    description: [
      'famous-person-page.ayr.description.queen-elizabeth-ii',
      'Ruled for over 50 years, guiding the UK and other realms through significant social and political change.',
    ],
    cardPortrait: cardPortraits.queenElizabethII,
    portrait: portraits.queenElizabethII,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.QUEEN_VICTORIA]: {
    personId: 'L1S2-QT6',
    color: '#f68d2e',
    givenName: 'Queen Victoria',
    surname: 'Queen Victoria',
    fullName: 'Queen Victoria',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.queen-victoria',
      'Queen from 1837 to 1901, a period of industrial, political, and scientific advancement in the British Empire.',
    ],
    cardPortrait: cardPortraits.queenVictoria,
    portrait: portraits.queenVictoria,
    backupShareImage: backupShareImages.queenVictoria,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.ROBERTO_CLEMENTE]: {
    personId: 'LR15-W2P',
    color: '#f68d2e',
    givenName: 'Roberto',
    surname: 'Clemente',
    fullName: 'Roberto Clemente',
    gender: 'MALE',
    isHispanic: true,
    isLiving: false,
    backupShareImage: backupShareImages.robertoClemente,
    description: [
      'famous-person-page.ayr.description.roberto-clemente',
      'Puerto Rican professional baseball Hall of Famer known also for his impactful charity work.',
    ],
    cardPortrait: cardPortraits.robertoClemente,
    portrait: portraits.robertoClemente,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.ROSA_PARKS]: {
    personId: 'LRP3-P5C',
    color: '#f68d2e',
    givenName: 'Rosa',
    surname: 'Parks',
    fullName: 'Rosa Parks',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.rosa-parks',
      'American civil rights activist best known for her heroic actions that led to the Montgomery bus boycott.',
    ],
    cardPortrait: cardPortraits.rosaParks,
    portrait: portraits.rosaParks,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.SEAN_ASTIN]: {
    cisId: 'cis.user.MMSG-YXND',
    color: '#f68d2e',
    givenName: 'Sean',
    surname: 'Astin',
    fullName: 'Sean Astin',
    gender: 'MALE',
    isHispanic: false,
    isLiving: true,
    description: [
      'famous-person-page.ayr.description.sean-astin',
      'American actor, known for roles in <0>The Lord of the Rings</0>, <0>The Goonies</0>, <0>Toy Soldiers</0>, and <0>Rudy</0>.',
    ],
    profession: 'ACTOR',
    cardPortrait: cardPortraits.seanAstin,
    portrait: portraits.seanAstin,
    backupShareImage: backupShareImages.seanAstin,
    eventId: getRootstech2022EventId(),
    languageSpecific: ['en'],
    pageIsExpired: true,
  },
  [famousPeople.SHIRLEY_TEMPLE]: {
    personId: 'L66C-WGD',
    color: '#f68d2e',
    givenName: 'Shirley',
    surname: 'Temple',
    fullName: 'Shirley Temple',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.shirley-temple',
      'American child actress, singer, and dancer. Later became a diplomat, a US Ambassador, and US Chief of Protocol.',
    ],
    cardPortrait: cardPortraits.shirleyTemple,
    portrait: portraits.shirleyTemple,
    backupShareImage: backupShareImages.shirleyTemple,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.SIMON_BOLIVAR]: {
    personId: 'MD4F-B84',
    color: '#f68d2e',
    givenName: 'Simón',
    surname: 'Bolívar',
    fullName: 'Simón Bolívar',
    gender: 'MALE',
    isHispanic: true,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.simon-bolivar',
      'Venezuelan military and political leader who helped free much of South America from Spanish rule.',
    ],
    cardPortrait: cardPortraits.simonBolivar,
    portrait: portraits.simonBolivar,
    backupShareImage: backupShareImages.simonBolivar,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.STEVE_ROCKWOOD]: {
    cisId: 'cis.user.MMMM-VWMX',
    color: '#f68d2e',
    givenName: 'Steve',
    surname: 'Rockwood',
    fullName: 'Steve Rockwood',
    gender: 'MALE',
    isHispanic: false,
    isLiving: true,
    description: [
      'famous-person-page.ayr.description.steve-rockwood',
      'President and CEO of FamilySearch International.',
    ],
    // linkUrl: '',
    // linkDisplay: '',
    profession: 'ACTOR',
    // youtubeVideoId: 'iOWcMlxHpbQ',
    cardPortrait: cardPortraits.steveRockwood,
    portrait: portraits.steveRockwood,
    backupShareImage: backupShareImages.steveRockwood,
    eventId: getRootstech2022EventId(),
    pageIsExpired: false,
  },
  [famousPeople.SUSAN_B_ANTHONY]: {
    personId: 'L7GY-8LD',
    color: '#f68d2e',
    givenName: 'Susan B.',
    surname: 'Anthony',
    fullName: 'Susan B. Anthony',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.susan-b-anthony',
      'American civil rights and women’s rights activist; leader in the women’s suffrage movement.',
    ],
    cardPortrait: cardPortraits.susanBAnthony,
    portrait: portraits.susanBAnthony,
    backupShareImage: backupShareImages.susanBAnthony,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.THAIS_PACHOLEK]: {
    cisId: 'cis.user.MMS6-T975',
    color: '#f68d2e',
    givenName: 'Thaís',
    surname: 'Pacholek',
    fullName: 'Thaís Pacholek',
    gender: 'FEMALE',
    isHispanic: true,
    isLiving: true,
    description: [
      'famous-person-page.ayr.description.thais-pacholek',
      'Brazilian movie, stage, and television actress is also a devoted advocate for childhood education.',
    ],
    // linkUrl: '',
    // linkDisplay: '',
    profession: 'ACTOR',
    youtubeVideoId: 'UkxntkZoFMc',
    cardPortrait: cardPortraits.thaisPacholek,
    portrait: portraits.thaisPacholek,
    backupShareImage: backupShareImages.thaisPacholek,
    eventId: getRootstech2022EventId(),
    pageIsExpired: true,
  },
  [famousPeople.TIRADENTES]: {
    personId: 'GQM5-CBS',
    color: '#f68d2e',
    givenName: 'Alferes Joaquim José',
    surname: 'da Silva Xavier',
    fullName: 'Tiradentes',
    gender: 'MALE',
    isHispanic: true,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.tiradentes',
      'This colonial Brazilian dentist and revolutionary hero gave his life in pursuit of an independent republic.',
    ],
    cardPortrait: cardPortraits.tiradentes,
    portrait: portraits.tiradentes,
    backupShareImage: backupShareImages.tiradentes,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.TRUMAN_ANGELL]: {
    personId: 'K2QF-MM1',
    color: '#f68d2e',
    givenName: 'Truman',
    surname: 'Angell',
    fullName: 'Truman O. Angell',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.truman-angell',
      '19th Century American and Latter-day Saint architect, most famous for designing the Salt Lake Temple.',
    ],
    cardPortrait: cardPortraits.trumanAngell,
    portrait: portraits.trumanAngell,
    backupShareImage: backupShareImages.trumanAngell,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.WALT_DISNEY]: {
    personId: 'L84S-ZRN',
    color: '#f68d2e',
    givenName: 'Walt',
    surname: 'Disney',
    fullName: 'Walt Disney',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.walt-disney',
      'American animator, film producer, and business pioneer in family friendly entertainment.',
    ],
    cardPortrait: cardPortraits.waltDisney,
    portrait: portraits.waltDisney,
    backupShareImage: backupShareImages.waltDisney,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.WILBUR_WRIGHT]: {
    personId: 'LC3Z-CRX',
    color: '#f68d2e',
    givenName: 'Wilbur',
    surname: 'Wright',
    fullName: 'Wilbur Wright',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.wilbur-wright',
      'American inventor; designed, built, and flew the first airplane with his brother Orville.',
    ],
    cardPortrait: cardPortraits.wilburWright,
    portrait: portraits.wilburWright,
    backupShareImage: backupShareImages.wilburWright,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.WILLIAM_SHAKESPEARE]: {
    personId: 'L7B2-36W',
    color: '#f68d2e',
    givenName: 'William',
    surname: 'Shakespeare',
    fullName: 'William Shakespeare',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.william-shakespeare',
      'English playwright, poet, and actor, widely regarded as the greatest writer in the English language.',
    ],
    cardPortrait: cardPortraits.williamShakespeare,
    portrait: portraits.williamShakespeare,
    backupShareImage: backupShareImages.williamShakespeare,
    eventId: getRootstech2022EventId(),
  },
  [famousPeople.WINSTON_CHURCHILL]: {
    personId: 'LC32-HZ6',
    color: '#f68d2e',
    givenName: 'Winston',
    surname: 'Churchill',
    fullName: 'Winston Churchill',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.winston-churchill',
      'Prime minister of the United Kingdom during World War II. Known for his inspirational speeches.',
    ],
    cardPortrait: cardPortraits.winstonChurchill,
    portrait: portraits.winstonChurchill,
    backupShareImage: backupShareImages.winstonChurchill,
    eventId: getRootstech2022EventId(),
  },
}

const famousPeoplePendingApprovalDetails = {
  [famousPeoplePendingApproval.MARILYN_MONROE]: {
    personId: 'LCC1-LQ3',
    color: '#f68d2e',
    givenName: 'Marilyn',
    surname: 'Monroe',
    fullName: 'Marilyn Monroe',
    gender: 'FEMALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.marilyn-monroe',
      'American actress, model, and singer. Overcame a troubled childhood to become a worldwide cultural icon.',
    ],
    cardPortrait: cardPortraits.marilynMonroe,
    portrait: portraits.marilynMonroe,
    backupShareImage: backupShareImages.marilynMonroe,
    eventId: getRootstech2022EventId(),
  },
  [famousPeoplePendingApproval.PELE]: {
    personId: 'GJ2G-LPX',
    color: '#f68d2e',
    givenName: 'Pelé',
    surname: 'Pelé',
    fullName: 'Pelé',
    gender: 'MALE',
    isHispanic: false,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.pele',
      'Brazilian footballer and international celebrity. Considered by many to be the greatest player of all time.',
    ],
    cardPortrait: cardPortraits.pele,
    portrait: portraits.pele,
    backupShareImage: backupShareImages.pele,
    eventId: getRootstech2022EventId(),
  },
  [famousPeoplePendingApproval.VICENTE_FERNANDEZ]: {
    personId: 'L11G-YLR',
    color: '#f68d2e',
    givenName: 'Vicente',
    surname: 'Fernández',
    fullName: 'Vicente Fernández',
    gender: 'MALE',
    isHispanic: true,
    isLiving: false,
    description: [
      'famous-person-page.ayr.description.vicente-fernandez',
      'Mexican singer, actor, and film producer. Commonly known as “El Rey de la Música Ranchera.”',
    ],
    cardPortrait: cardPortraits.vicenteFernandez,
    portrait: portraits.vicenteFernandez,
    eventId: getRootstech2022EventId(),
  },
}

const latinAmericaOrder = [
  famousPeople.ROBERTO_CLEMENTE,
  famousPeople.SIMON_BOLIVAR,
  famousPeople.JUAN_DE_GARAY,
  famousPeople.BENITO_JUAREZ,
  famousPeople.GABRIEL_GARCIA_MARQUEZ,
  famousPeople.WALT_DISNEY,
  famousPeople.QUEEN_VICTORIA,
  famousPeople.GEORGE_VI,
  famousPeople.ALBERT_EINSTEIN,
]

const otherFamousPeopleToShowFirst = {
  en: [
    famousPeople.WALT_DISNEY,
    famousPeople.JANE_AUSTEN,
    famousPeople.ROSA_PARKS,
    famousPeople.GEORGE_WASHINGTON,
    famousPeople.MUHAMMAD_ALI,
    famousPeople.SIMON_BOLIVAR,
    famousPeople.CHARLES_DICKENS,
    famousPeople.NELSON_MANDELA,
  ],
  es: latinAmericaOrder,
  pt: latinAmericaOrder,
}

const updateEvent = (object, newEventIdIsOn) => {
  if (newEventIdIsOn) {
    Object.fromEntries(
      Object.entries(object).map(([key, value]) => {
        value.eventId = getRootstech2022EventId(newEventIdIsOn)
        return [key, value]
      })
    )
  }
  return object
}

export const getFamousPersonDetails = (personKey, newEventIdIsOn) => {
  const famousPeopleObjects = updateEvent(
    { ...famousPeopleDetails, ...famousPeoplePendingApprovalDetails },
    newEventIdIsOn
  )

  return famousPeopleObjects[personKey]
}

export const getAllFamousPeople = (locale, includePendingApproval, newEventIdIsOn) => {
  let famousPeopleObjects = updateEvent(famousPeopleDetails, newEventIdIsOn)
  if (includePendingApproval)
    famousPeopleObjects = { ...famousPeopleObjects, ...updateEvent(famousPeoplePendingApprovalDetails, newEventIdIsOn) }

  const showFirstOrder = otherFamousPeopleToShowFirst[locale] || []
  const shownFirst = []
  const shownLast = []
  Object.entries(famousPeopleObjects).forEach(([key, value]) => {
    value.personKey = key
    if (showFirstOrder.includes(key)) shownFirst.push(value)
    else shownLast.push(value)
  })
  shownFirst.sort((a, b) => showFirstOrder.indexOf(a.personKey) - showFirstOrder.indexOf(b.personKey))
  shownLast.sort((a, b) => a.fullName.localeCompare(b.fullName))

  const allShown = [...shownFirst, ...shownLast].filter(
    (personObject) => !personObject.languageSpecific || personObject.languageSpecific.includes(locale)
  )
  return allShown
}

export function getRootstech2022EventId(newEventIdisOn) {
  const rootstechEvents = {
    prod: newEventIdisOn ? 'b72a8a10-4959-4bd5-b39d-f99b00e8a946' : '787b5034-edf8-465c-b6fa-f301452de294',
    beta: '8ceedfe4-9720-4292-be42-72f9e1da842d',
    int: '52748738-a605-41cf-a387-517f3c4d780b',
  }
  let eventId = rootstechEvents.prod
  if (baseUrl.includes('beta')) {
    eventId = rootstechEvents.beta
  } else if (baseUrl.includes('integration')) {
    eventId = rootstechEvents.int
  }
  return eventId
}
